import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useProductContext } from '../context/productcontext';
import ProductBox from '../components/ProductBox';
import Slider from "react-slick";
import { omit } from 'lodash'
import $ from 'jquery';
import secureLocalStorage from "react-secure-storage";
import moment from 'moment';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

function Product_detail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { products } = useProductContext();
    const [data, setData] = useState(null);
    const [MainIMG, setMainIMG] = useState(null);
    const [variation, setVariation] = useState([]);
    const [cart_var, setCartVar] = useState([]);
    const [errors, setErrors] = useState({});
    const [review_post, setreview_post] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [rating_prd, setRating] = useState(null);
    const [rating_sup, setSupRating] = useState(null);
    const [showReview, setShowReview] = useState(false);

    const CheckPurchased = () => {
        axios.get(`https://esquare.demo-customproject.com:3002/check-purchase/${JSON.parse(secureLocalStorage.getItem('login_data'))?.id}/${id}/`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            if(res.status === 200){
                setShowReview(true)
            }else{
                setShowReview(false)
            }
        });
    }
    useEffect(() => {
        if(secureLocalStorage.getItem('login_data')){
            CheckPurchased();
        }
    },[id])
    const getPRD = () => {
        axios.get(`https://esquare.demo-customproject.com:3002/single-product/${id}`).then((res) => {
            setData(res.data.data[0]);
            setVariation(res.data.variation)
            if (secureLocalStorage.getItem('login_data') !== null) {
                setreview_post({
                    product_id: id,
                    user_id: JSON.parse(secureLocalStorage.getItem('login_data')).id,
                    supp_id: res.data.data[0].user_id,
                    rating: '',
                    comment: '',
                });
            }
            axios.get(`https://esquare.demo-customproject.com:3002/get-rating-supplier/${res.data.data[0].user_id}`).then((res) => {
                setSupRating(res.data)
            })
        });
        axios.get(`https://esquare.demo-customproject.com:3002/get-rating-product/${id}`).then((res) => {
            setRating(res.data)
        })
    }

    const formatter = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
    });


    const [counter, setCounter] = useState(1);
    let incrementCounter = () => {
        if(counter < data?.quantity) {
            setCounter(counter + 1);
        }
    }
        
    let decrementCounter = () => setCounter(counter - 1);
    if (counter <= 1) {
        decrementCounter = () => setCounter(1);
    }

    const validate = (name, value) => {
        switch (name) {
            case 'rating':
                if (value == undefined || value == '') {
                    setErrors({
                        ...errors,
                        rating: 'Click star rate out of 5'
                    })
                } else {
                    let newObj = omit(errors, "rating");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }
    let err = {};
    const validate2 = (name, value) => {
        if (name == 'rating') {
            if (value == undefined || value == '') {
                err = { ...err, rating: 'Click star rate out of 5' }
            } else {
                let newObj = omit(errors, "rating");
                setErrors(newObj);
            }
        }
        setErrors(err)
    }

    const setVal = (e) => {
        setreview_post((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        validate(e.target.name, e.target.value);
    }
    const setVarnt = (e) => {
        setCartVar((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    var prd_slider = {
        dots: false,
        infinite: false,
        speed: 300,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 921,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        ]
    };

    useEffect(() => {
        $('.scrl-btnn').unbind().click(function () {
            $('.review-scroller .slick-next').click();
        })
    })

    const addCart = (e) => {
        e.preventDefault();
        if (Object.keys(cart_var).length !== variation.length) {
            $('.err-msg').addClass('show');
            return false;
        }
        else {
            $('.err-msg').removeClass('show');
            const cartData = {
                'id': data.id,
                'price': data.price,
                'qunatity': counter,
                'vartions': cart_var,
                'stock': data.quantity,
                'weight': data.weight,
                'length': data.length,
                'girth': data.girth,
            }
            let strg_name = '';
            if (Object.keys(cart_var).length !== 0) {
                Object.keys(cart_var).map((ver) => {
                    strg_name = strg_name + cart_var[ver];
                })
            }
            strg_name = strg_name + data.id;
            secureLocalStorage.setItem('cart_data', {
                ...secureLocalStorage.getItem('cart_data'),
                [strg_name]: JSON.stringify(cartData),
            });
            $('.msg-box').addClass('green');
            window.dispatchEvent(new Event('storage'))
            setTimeout(function () {
                navigate('/cart')
            }, 1000)
        }
    }

    const BuyNow = (e) => {
        e.preventDefault();
        if (Object.keys(cart_var).length !== variation.length) {
            $('.err-msg').addClass('show');
            return false;
        }
        else {
            const Pcart = secureLocalStorage.getItem('cart_data');
            secureLocalStorage.setItem('Pcart_data', Pcart);
            $('.err-msg').removeClass('show');
            const cartData = {
                'id': data.id,
                'price': data.price,
                'qunatity': counter,
                'vartions': cart_var,
                'stock': data.quantity,
                'weight': data.weight,
                'length': data.length,
                'girth': data.girth,
            }
            let strg_name = '';
            if (Object.keys(cart_var).length !== 0) {
                Object.keys(cart_var).map((ver) => {
                    strg_name = strg_name + cart_var[ver];
                })
            }
            strg_name = strg_name + data.id;
            secureLocalStorage.setItem('cart_data', {
                [strg_name]: JSON.stringify(cartData),
            });
            $('.msg-box').addClass('green');
            window.dispatchEvent(new Event('storage'))
            setTimeout(function () {
                navigate('/checkout')
            }, 1000)
        }
    }

    const GetReview = async () => {
        await axios.get(`https://esquare.demo-customproject.com:3002/get-reviews/${id}`).then((res) => {
            setReviews(res.data);
        });
    }

    const AddReview = (e) => {
        e.preventDefault();
        e.target.classList.add('loadin');
        Object.keys(review_post).map((ket) => {
            validate2(ket, review_post[ket]);
        })
        if (Object.keys(errors).length == 0) {
            axios.post(`https://esquare.demo-customproject.com:3002/add-review`, review_post, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
                setreview_post((prev) => ({ ...prev, 'rating': "" }));
                setreview_post((prev) => ({ ...prev, 'comment': "" }));
                e.target.classList.remove('loadin');
                $('[name="comment"]').val('')
                $('[name="rating"]').prop('checked', false);
                GetReview();
            })
        } else {
            e.target.classList.remove('loadin');
        }
    }

    const [liked, setLiked] = useState(0);
    const GetWishlist = async () => {
        await axios.post(`https://esquare.demo-customproject.com:3002/get-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data')).id}/${id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            setLiked(res.data[0].status);
            GetWishlist_count()
        });
    }
    const GetWishlist_count = async () => {
        await axios.post(`https://esquare.demo-customproject.com:3002/get-user-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            $('.center-sec-icon ul li i.far.fa-heart ~ span').text(res.data.length);
        });
    }
    const Wishlist = async (e) => {
        e.preventDefault();
        await axios.post(`https://esquare.demo-customproject.com:3002/alter-wishlist/${JSON.parse(secureLocalStorage.getItem('login_data')).id}/${id}`, null, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            setLiked(res.data[0].status);
            GetWishlist_count()
        });
    }

    useEffect(() => {
        getPRD();
        GetReview();
        GetWishlist();
    }, [id])
    if (data === null) {
        return <div className='Loading'></div>
    }
    else if (data === undefined) {
        window.location.href = "/404"
    } else if (Object.keys(products).length === 0 || rating_prd === null || rating_sup === null) {
        return <div className='Loading'></div>
    }
    return (
        <>
            <section className="product-detail pt-8">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="product-content">
                                <div className="top-section">
                                    <h2>{data.title}</h2>
                                    <div className="row d-flex price_rev">
                                        <div className="col-6">
                                            <h3>
                                                {/* <span class="prod-det-real-rp">$48.50</span> */}
                                                <span className="prod-det-disc-rp"> £{data.price}</span>
                                                <strike>£{data.cut_price}</strike>
                                            </h3>
                                        </div>
                                        <div className="col-6">
                                            <ul>
                                                <li><div className="product-rate-cover mb-15">
                                                    <div className="product-rate d-inline-block">
                                                        <div className="product-rating" style={{ width: (rating_prd.rating === null ? '0' : rating_prd.rating * 100) / 5 + '%' }} />
                                                    </div>
                                                    <span className="font-small ml-5 text-muted"> ({rating_prd.rating === null ? '0' : rating_prd.rating.toFixed(1)} Out of 5)</span>
                                                </div></li>
                                                <li><span> {rating_prd.count} Reviews</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <ul className="brand">
                                    <li>Brand: <small>{data.brand}</small></li>
                                    <li>Availability:<small> {data.quantity === 0 ? <span className='errorr'>Out of Stock</span> : "In Stock"}</small></li>
                                    {
                                        variation.length !== 0 ?
                                            variation.map((item) => {
                                                return <li>{item.name}
                                                    <div className='badges-variant'>
                                                        {
                                                            item.variants.split(',').map((ver) => {
                                                                return <div>
                                                                    <input name={item.name} value={ver} onClick={setVarnt} type='radio' />
                                                                    <span>{ver}</span>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </li>
                                            })
                                            : false
                                    }
                                </ul>
                                <p>{data.short_description}</p>
                                <div className="functionality-box">
                                    <ul>
                                        {data.quantity !== 0 ? <>
                                            <li>
                                                <div className="num-block skin-2">
                                                    <div className="num-in">
                                                        <span className="minus" onClick={decrementCounter} />
                                                        <input type="number" value={counter} min={1} onChange={(e) => {
                                                            if (e.target.value < 1) {
                                                                setCounter(1)
                                                            } else {
                                                                setCounter(e.target.value)
                                                            }
                                                        }} id="qtuDa" className="in-num" defaultValue={1} />
                                                        <span className="plus" onClick={incrementCounter} />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="addtocart "><Link className="btn13" to="#" onClick={addCart}>Add To Cart</Link></div>
                                            </li>
                                            <li>
                                                <div className="addtocart "><Link className="btn13" to="#" onClick={BuyNow}>Buy Now</Link></div>
                                            </li>
                                        </> : false}
                                        {
                                            secureLocalStorage.getItem('login_data') !== null ? <li>
                                                <div className={`heart-button ${liked === 1 ? 'liked' : false}`}><a href="javascript:;" onClick={Wishlist}><i className="far fa-heart" /></a></div>
                                            </li> : <li>
                                                <div className="heart-button"><Link to={`/login?${data.id}?edocrofttpd`}><i className="far fa-heart" /></Link></div>
                                            </li>
                                        }

                                    </ul>
                                    <div className="msg-box">Product added to Cart</div>
                                    <div className='err-msg'>Select variation before adding to cart</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="product-body">
                                <img src={MainIMG === null ? `https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${data.user_id}/${data.thumbnail}` : MainIMG} className="img-fluid" alt="img" />
                            </div>
                            {data.images === null || data.images === '' ? false :
                                <Slider {...prd_slider}>
                                    {
                                        data.images.split(',').map((img) => {
                                            return <div className='prd-img' onClick={() => { setMainIMG(`https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${data.user_id}/${img}`) }}>
                                                <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/products/ath_${data.user_id}/${img}`} className="img-fluid" alt="img" />
                                            </div>
                                        })
                                    }
                                </Slider>}
                        </div>
                    </div>
                    <div className="row mt-5 align-items-center">
                        <div className="col-md-8">
                            <div className="prod_description">
                                <h4>Description</h4>
                                <p>{renderHTML(data.description)}</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="archive-header-3 p-4">
                                <div className="archive-header-3-inner d-flex gap-4">
                                    <div className="vendor-logo mr-50" style={{ 'height': '80px', 'width': '80px' }}>
                                        <img src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/users/${data.profile_pic ? data.profile_pic : "vendor-icon.png"}`} />
                                    </div>
                                    <div className="vendor-content box">
                                        <div className="product-category">
                                            <span className="text-muted">Since {formatter.format(Date.parse(data.created_at))}</span>
                                        </div>
                                        <h3 className="mb-2 text-white">
                                            <Link className="text-white" to={`/vendor-detail/${data.user_id}`}>{data.first_name + ' ' + data.last_name}</Link>
                                        </h3>
                                        <div className="product-rate-cover mb-15">
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating" style={{ width: (rating_sup.rating === null ? "0" : rating_sup.rating * 100) / 5 + '%' }} />
                                            </div>
                                            <span className="font-small ml-5 text-muted"> ({rating_sup.rating === null ? "0" : rating_sup.rating.toFixed(1)})</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="reviews-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 centerCol">
                            <div className='row justify-content-center'>
                                {
                                    secureLocalStorage.getItem('login_data') !== null && showReview ? <div className='col-md-4'>
                                        <div className='review-form'>
                                            <h3>Add you review</h3>
                                            <div class="rate">
                                                <input type="radio" onClick={setVal} id="star5" name="rating" defaultValue="5" />
                                                <label for="star5" title="text">5 stars</label>
                                                <input type="radio" onClick={setVal} id="star4" name="rating" defaultValue="4" />
                                                <label for="star4" title="text">4 stars</label>
                                                <input type="radio" onClick={setVal} id="star3" name="rating" defaultValue="3" />
                                                <label for="star3" title="text">3 stars</label>
                                                <input type="radio" onClick={setVal} id="star2" name="rating" defaultValue="2" />
                                                <label for="star2" title="text">2 stars</label>
                                                <input type="radio" onClick={setVal} id="star1" name="rating" defaultValue="1" />
                                                <label for="star1" title="text">1 star</label>
                                            </div>
                                            {errors.rating ? <div className='errorr'>{errors.rating}</div> : false}

                                            <textarea onChange={setVal} name="comment" placeholder='Comment'></textarea>
                                            {errors.comment ? <div className='errorr'>{errors.comment}</div> : false}
                                            <div className="auth-btn mt-4">
                                                <button className="submit-btn" onClick={AddReview}>Post</button>
                                            </div>
                                        </div>
                                    </div> : false
                                }
                                <div className='col-md-8'>
                                    {
                                        reviews === null ? <div className='Loading'></div> : <>
                                            <h3 className='mb-4'>Reviews ({reviews.length})</h3>
                                            <div className='review-scroller'>
                                                {reviews.length === 0 ? <div className='notfound'>No Reviews Found</div> : <div className='review-scrollr'>
                                                    {reviews.map((item) => {
                                                        return <div className="reviews-members">
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <div className="reviews-members-header">
                                                                        {
                                                                            item.profile_pic === null || item.profile_pic === '' ? <span className='profile_picc mr-3 rounded-pill'>{item.first_name[0]}</span> : <img alt="Generic placeholder image" src={`https://esquare.demo-customproject.com/admin/backend/public/uploads/users/${item.profile_pic}`} className="mr-3 rounded-pill" />
                                                                        }
                                                                        <div>
                                                                            <span className="star-rating float-right">
                                                                                {
                                                                                    (() => {
                                                                                        const arr = [];
                                                                                        for (let i = 0; i < 5; i++) {
                                                                                            if (i < item.rating) {
                                                                                                arr.push(<i className="fa-solid fa-star"></i>)
                                                                                            } else {
                                                                                                arr.push(<i className="fa-light fa-star"></i>)
                                                                                            }
                                                                                        }
                                                                                        return arr;
                                                                                    })()
                                                                                }
                                                                            </span>
                                                                            <h6 className="mb-1">
                                                                                <a className="text-black" href="javascript:;">{item.first_name} {item.last_name}</a>
                                                                            </h6>
                                                                            <p className="text-gray">{moment(item.created_at).format('MMMM Do YYYY')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reviews-members-body">
                                                                        <p>{item.comment}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>}
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="related-prod all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 centerCol">
                            <div className="sec-heading text-center">
                                <h2>Related Products</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {
                            products.filter((item) => {
                                return item.type_category == data.type_category || item.sub_category == data.sub_category;
                            }).map((prd, key) => {
                                if (key < 6) {
                                    return <div className="col-lg-2 col-md-4 mb-4"><ProductBox {...prd} /></div>
                                }
                            })
                        }
                    </div>
                </div>
            </section>



        </>
    )
}

export default Product_detail