import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import Account_Sidebar from '../components/Account_Sidebar';
import ProductBox from '../components/ProductBox';
import { Link } from 'react-router-dom';

const Most_Selling = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    if(JSON.parse(secureLocalStorage.getItem('login_data')).role != 2){
        window.location.replace('/login');
    }
    const [products, setProducts] = useState(null);
    const [searchQ, SetSearchQ] = useState(null);
    const GetData = () => {
        axios.get(`https://esquare.demo-customproject.com:3002/most-selling/${JSON.parse(secureLocalStorage.getItem('login_data')).id}`, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } }).then((res) => {
            console.log(res);
            setProducts(res.data.data);
        })
    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <section className="user-area">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-2 p-0"><Account_Sidebar /></div>
                    <div className="col-md-10 p-0">
                        <div className="body-wrapper">
                            <div className="userInfo">
                                <div className='switch-box-head'>
                                    <h3>Most Selling Products</h3>
                                    <div className='serach-boxxs'>
                                        <input type='text' placeholder='Search Products' onKeyUp={(e) => { SetSearchQ(e.target.value) }} />
                                    </div>
                                </div>
                                {
                                    !products ? <div className='Loading' /> : <div className='row'>
                                        {
                                            products
                                                .sort((a, b) => b.sale_count - a.sale_count)
                                                .filter((item) => {
                                                    if (searchQ === null || searchQ === '') {
                                                        return item;
                                                    } else {
                                                        return item.title.toLowerCase().includes(searchQ.toLowerCase());
                                                    }
                                                })
                                                .map((item) => {
                                                    return (
                                                        <div className='col-lg-2 col-md-3 mb-4 position-relative'>
                                                            <ProductBox {...item} />
                                                            <div className='action-btnss'>
                                                                <Link to={`/edit-products/${item.id}`}>Edit</Link>
                                                            </div>
                                                            <span className='sale_count-in'>{item.sale_count} Sold</span>
                                                        </div>
                                                    );
                                                })
                                        
                                        }
                                        <div className='notfound'>No Products Found</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Most_Selling
