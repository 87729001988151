import React, { useState } from 'react'
import axios from 'axios';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

const Forgot_Password = () => {
    if (secureLocalStorage.getItem('login_data') != null) {
        window.location.replace('/');
    }
    const [email, setEmail] = useState({});
    const navigate = useNavigate();
    const setVal = (e) => {
        setEmail((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    const SendOtp = async (e) => {
        e.preventDefault();
        if (email.email != '') {
            await axios.post('https://esquare.demo-customproject.com:3002/forgot-pass', email).then(function (response) {
                if (response.data.status == '200') {
                    $('.msg-box').removeClass('red')
                    $('.msg-box').addClass('green').text(response.data.message);
                    setEmail({});
                    $('.aut-form-box input').val('');
                    secureLocalStorage.setItem('forgot_email', JSON.stringify(email));
                    setTimeout(function () {
                        navigate('/otp-verify')
                    }, 1000)
                } else {
                    $('.msg-box').removeClass('green')
                    $('.msg-box').addClass('red').text(response.data.message);
                }
            }).catch(function (error) {
            });
        }
    }
    return (
        <section className="contact-page-main py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 centerCol">
                        <div className="auth-head">
                            <h2>Forgot Password</h2>
                            <div className="aut-form-box">
                                <form action="#">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input className='form-control' type="email" name="email" onChange={setVal} id placeholder="Email Address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 text-center">
                                            <div className="auth-btn">
                                                <button className='w-100 mt-4 login_btn' onClick={SendOtp}> Send Email OTP</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 d-flex align-items-center justify-content-end mt-4">
                                            <Link to="/login">Login</Link>
                                        </div>
                                        <div className='msg-box'></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forgot_Password