import React from 'react'
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import TimeAgo from '../components/TimeAgo';
const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });

const Account_Sidebar = () => {
    if (secureLocalStorage.getItem('login_data') == null) {
        window.location.replace('/login');
    }
    useEffect(() => {
        var str = window.location.href.toLowerCase();
        $(".side-bar>a").each(function () {
            if (str.indexOf(this.href.toLowerCase()) > -1) {
                $(".side-bar>a.active").removeClass("active");
                $(this).addClass("active");
            }
        });
    })
    const Logout = (e) => {
        e.preventDefault();
        secureLocalStorage.removeItem('login_data');
        secureLocalStorage.removeItem('accessToken');
        window.location.href = "/"
    }


    const [notify, setNotifications] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const Get_Not = async () => {
        try {
            const res = await axios.post('https://esquare.demo-customproject.com:3002/get-notification', { user_id: JSON.parse(secureLocalStorage.getItem('login_data')).id }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } });
            setNotifications(res.data);
        } catch (error) {
            console.log(error)
        }
    }
    const Not_Readed = async (id) => {
        try {
            const url = "https://esquare.demo-customproject.com:3002/notify-read";
            const res = await axios.post(url, { user_id: JSON.parse(secureLocalStorage.getItem('login_data')).id, id: id, }, { headers: { 'Auth': secureLocalStorage.getItem('accessToken') } });
            setNotifications(res.data);
            setIsOpen(false);
        } catch (error) {
        }   
    }

    useEffect(() => {
        Get_Not();
    }, [])


    return (
        <div className="side-bar">
            <Link to="/profile"><i className="fa-light fa-user" /> Profile </Link>
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/my-store"><i className="fa-light fa-folder"></i> My Store </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/most-selling"><i class="fa-brands fa-sellsy"></i> Most Selling </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/most-rated"><i class="fa-solid fa-star"></i> Most Rated </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/returning-costumers"><i class="fa-sharp fa-regular fa-users"></i> Returning Costumers </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/add-product-to-deals"><i class="fa-solid fa-badge-percent"></i> Deals On Products </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/add-products"><i className="fa-light fa-file-arrow-up"></i> Add New product </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role !== 2 ? <Link to="/to-review"><i className="fa-light fa-star"></i> To Review </Link> : false}
            {JSON.parse(secureLocalStorage.getItem('login_data')).role == 2 ? <Link to="/orders-listing"><i className="fa-sharp fa-light fa-rectangle-history"></i> Order Recieved </Link> : false}
            <Link to="/wishlist"><i className="fa-light fa-heart"></i> Wishlist </Link>
            {JSON.parse(secureLocalStorage.getItem('login_data')).role !== 2 ? <Link to="/orders"><i className="fa-light fa-lock-keyhole-open"></i> Orders </Link> : false}
            <a href="#" onClick={Logout}><i className="fa-sharp fa-light fa-arrow-right-from-bracket fa-flip-horizontal"></i> Logout</a>


            {/*Notification*/}
            <div className="dropdown d-inline-block" ref={dropdownRef}>
                <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    id="page-header-notifications-dropdown"
                    onClick={toggleDropdown}
                    aria-haspopup="true"
                    aria-expanded={isOpen}
                >
                    <i class="fa-regular fa-bell"></i>
                    <span className="badge bg-danger rounded-pill">{notify && notify.filter(n => n.read_at === null).length}</span>
                </button>
                {isOpen && (
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show" aria-labelledby="page-header-notifications-dropdown">
                        <div className="p-3">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h6 className="m-0" key="t-notifications"> Notifications </h6>
                                </div>
                            </div>
                        </div>
                        <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                            {
                                notify && notify.length ? notify.map((item) => {
                                    return (
                                        <Link key={item.id} to={item.url} onClick={() => { Not_Readed(item.id) }} className={`text-reset notification-item ${!item.read_at && 'new'}`}>
                                            <div dangerouslySetInnerHTML={{ __html: item.data }} />
                                            <span className="t-min-ago">
                                                <i className="mdi mdi-clock-outline" />
                                                <TimeAgo date={item.created_at} />
                                            </span>
                                        </Link>
                                    );
                                }) : <div className='notfound'>Not found</div>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Account_Sidebar